section.section-4
{
	/*padding-top: 70px;*/
	/*background-image: linear-gradient(180deg, rgb(112 17 79) 0%, rgba(97,0,78,1) 60%, rgba(67,0,93,1) 100%);*/
	/*background-image: linear-gradient(180deg, rgb(97 0 98) 0%, rgb(97 0 98) 60%, rgba(67,0,93,1) 100%);*/
	background-color: rgb(97 0 98);
}
.numCircle
{
	background-color: #f1ddde;
	color: #610062;
	font-size: 3rem;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	text-align: center;
	display: flex;
	box-shadow: inset 0px 0px 0px 5px #610062;
	border: 5px solid #db545a;
}
.numCircle:after {
	content: "";
	display: block;
	padding-bottom: 100%;
}