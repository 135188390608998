*,
html,
body
{
	/*font-family: "Helvetica";*/
	font-family: 'Proxima Nova', sans-serif;
}
html,body
{
	width: 100%;
	overflow-x: hidden;
}
img.w100
{
	width: 100%;
}
img.mb50
{
	margin-bottom: 50px;
}
section
{
	color: #fff;
}
section h1.title
{
	font-size: 4.5rem;
}
section p.title
{
	font-size: 3rem;
	line-height: 3rem;
	color: #f1ddde;
	/*text-shadow: 2px 0 #db545a, -2px 0 #db545a, 0 2px #db545a, 0 -2px #db545a,
	1px 1px #db545a, -1px -1px #db545a, 1px -1px #db545a, -1px 1px #db545a;*/
	/*text-shadow: 2px 1px #db545a, -2px 1px #db545a, 1px 2px #db545a, 1px -2px #db545a,
	1px 0 #db545a, 0px -1px #db545a, 1px 0 #db545a, 0 1px #db545a;*/
}
section p.large-text
{
	font-size: 2rem;
}
section p.middle-text
{
	font-size: 1rem;
}

.spin-1s
{
	-webkit-animation:spin 1s linear infinite;
	-moz-animation:spin 1s linear infinite;
	animation:spin 1s linear infinite;
}
@-moz-keyframes spin {
	100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin { 
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform:rotate(360deg);
	}
}