section.section-6
{
	padding-top: 70px;
	background-image: linear-gradient(180deg, rgb(68 0 93) 0%, rgb(45 0 60) 50%, rgb(16 0 22) 100%);
}

section.section-6 .contactButton
{
	width: 100%;
	height: 50px;
	border-radius: 25px;
	font-size: 30px;
	text-align: center;
	cursor: pointer;
}
section.section-6 .contactButton.phone
{
	background-color: #075e54;
}
section.section-6 .contactButton.skype
{
	background-color: #00aff0;
}
section.section-6 .contactButton.whatsapp
{
	background-color: #29c44c;
}
section.section-6 .contactButton.email
{
	background-color: #af0000;
}