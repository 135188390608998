nav.navbar
{
	padding-left: 8px;
	padding-right: 8px;
}
nav div.container
{
	padding-top: .3rem;
	padding-right: .3rem;
	padding-bottom: .3rem;
	background-color: #fff;
	border-radius: 1rem;
}
nav div.container button.navbar-toggler
{
	border-radius: 1rem;
}